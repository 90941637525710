@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    [x-cloak] {
        display: none !important;
    }

    .font-heading {
        @apply font-bely-display;
    }

    .font-subheading {
        @apply font-bely;
    }

    .font-body {
        @apply font-open-sans font-light text-base;
    }

    .h1 {
        @apply font-heading text-5xl;
    }

    .h2 {
        @apply font-heading text-4xl;
    }

    .h3 {
        @apply font-heading text-3xl;
    }

    .translate-3d {
        -webkit-transform: translate3d(0,0,0);
    }
}

@layer components {
    .grid-overlap-right {
        @apply grid grid-cols-[2rem_auto_2rem] grid-rows-[auto_12rem_auto] lg:grid-cols-[1fr_15%_25%] lg:grid-rows-[5rem_1fr_5rem];
        .image { @apply col-start-1 col-end-4 row-start-1 row-end-3 lg:col-start-1 lg:col-end-3 lg:row-start-1 lg:row-end-4; }
        .text { @apply col-start-2 col-end-3 row-start-2 row-end-4 lg:col-start-2 lg:col-end-4 lg:row-start-2 lg:row-end-3; }
    }

    .grid-overlap-double-right {
        @apply grid grid-cols-[2rem_auto_2rem] grid-rows-[auto_12rem_auto] lg:grid-cols-[1fr_30%_25%] lg:grid-rows-[5rem_1fr_5rem];
        .image { @apply col-start-1 col-end-4 row-start-1 row-end-3 lg:col-start-1 lg:col-end-3 lg:row-start-1 lg:row-end-4; }
        .text { @apply col-start-2 col-end-3 row-start-2 row-end-4 lg:col-start-2 lg:col-end-4 lg:row-start-2 lg:row-end-3; }
    }

    .grid-overlap-left {
        @apply grid grid-cols-[2rem_auto_2rem] grid-rows-[auto_12rem_auto] lg:grid-cols-[25%_15%_1fr] lg:grid-rows-[5rem_1fr_5rem];
        .image { @apply col-start-1 col-end-4 row-start-2 row-end-4 lg:col-start-2 lg:col-end-4 lg:row-start-1 lg:row-end-4; }
        .text { @apply col-start-2 col-end-3 row-start-1 row-end-3 lg:col-start-1 lg:col-end-3 lg:row-start-2 lg:row-end-3; }
    }

    .grid-overlap-double-left {
        @apply grid grid-cols-[2rem_auto_2rem] grid-rows-[auto_12rem_auto] lg:grid-cols-[25%_30%_1fr] lg:grid-rows-[5rem_1fr_5rem];
        .image { @apply col-start-1 col-end-4 row-start-2 row-end-4 lg:col-start-2 lg:col-end-4 lg:row-start-1 lg:row-end-4; }
        .text { @apply col-start-2 col-end-3 row-start-1 row-end-3 lg:col-start-1 lg:col-end-3 lg:row-start-2 lg:row-end-3; }
    }

    .grid-overlap-bottom {
        @apply grid grid-cols-[2rem_auto_2rem] grid-rows-[auto_12rem_auto] lg:grid-cols-[5rem_auto_5rem] lg:grid-rows-[auto_8rem_auto];
        .image { @apply col-start-1 col-end-4 row-start-1 row-end-3; }
        .text { @apply col-start-2 col-end-3 row-start-2 row-end-4; }
    }

    .grid-overlap-double-bottom {
        @apply grid grid-cols-[2rem_auto_2rem] grid-rows-[auto_12rem_auto] lg:grid-cols-[2rem_auto_2rem] lg:grid-rows-[auto_8rem_auto];
        .image { @apply col-start-1 col-end-4 row-start-1 row-end-3; }
        .text { @apply col-start-2 col-end-3 row-start-2 row-end-4; }
    }

    .grid-overlap-top {
        @apply grid grid-cols-[2rem_auto_2rem] grid-rows-[auto_12rem_auto] lg:grid-cols-[5rem_auto_5rem] lg:grid-rows-[auto_8rem_auto];
        .image { @apply col-start-1 col-end-4 row-start-2 row-end-4; }
        .text { @apply col-start-2 col-end-3 row-start-1 row-end-3; }
    }

    .grid-overlap-double-top {
        @apply grid grid-cols-[2rem_auto_2rem] grid-rows-[auto_12rem_auto] lg:grid-cols-[2rem_auto_2rem] lg:grid-rows-[auto_8rem_auto];
        .image { @apply col-start-1 col-end-4 row-start-2 row-end-4; }
        .text { @apply col-start-2 col-end-3 row-start-1 row-end-3; }
    }

    .grid-overlay-left {
        @apply col-start-1 col-end-13 md:col-end-10 lg:col-end-8 xl:col-end-6;
    }

    .grid-overlay-right {
        @apply col-start-1 md:col-start-4 lg:col-start-6 xl:col-start-8 col-end-13;
    }
}

@layer utilities {
    .link-button {
        @apply rounded-full inline-block font-body font-semibold px-10 py-3;
    }

    .link-button-white {
        @apply link-button bg-white hover:bg-brand-red-900 text-black hover:text-white border border-white;
    }

    .link-button-outline-white {
        @apply link-button hover:bg-white text-white hover:text-black border border-white;
    }

    .link-button-brand-green-900 {
        @apply link-button bg-brand-green-900 hover:bg-white text-white hover:text-brand-green-900 border border-brand-green-900;
    }

    .link-button-outline-brand-green-900 {
        @apply link-button bg-brand-green-900 hover:bg-white text-white hover:text-brand-green-900 border border-white hover:border-brand-green-900;
    }
    /* COLOR THEMES */
    .theme-white {
        @apply bg-white text-black;
        h1, h2, h3, h4, h5 ,h6 { @apply text-black; }
        .btn { @apply link-button-brand-green-900; }
    }

    .theme-white-opacity {
        @apply bg-white/[.7] text-black;
        h1, h2, h3, h4, h5 ,h6 { @apply text-black; }
        .btn { @apply link-button-brand-green-900; }
    }

    .theme-charcoal {
        @apply bg-[#333333] text-white;
        h1, h2, h3, h4, h5 ,h6 { @apply text-white; }
        .btn { @apply link-button-white; }
    }

    .theme-charcoal-opacity {
        @apply bg-[#333333]/[.7] text-white;
        h1, h2, h3, h4, h5 ,h6 { @apply text-white; }
        .btn { @apply link-button-outline-white; }
    }

    .theme-brand-blue-900 {
        @apply bg-brand-blue-900 text-white;
        h1, h2, h3, h4, h5, h6 { @apply text-white; }
        .btn { @apply link-button-white; }
    }

    .theme-brand-blue-900-opacity {
        @apply bg-brand-blue-900/[.7] text-white;
        h1, h2, h3, h4, h5, h6 { @apply text-white; }
        .btn { @apply link-button-white; }
    }

    .theme-brand-green-500 {
        @apply bg-brand-green-500 text-brand-green-900;
        h1, h2, h3, h4, h5, h6 { @apply text-brand-green-900; }
        .btn { @apply link-button-brand-green-900; }
    }

    .theme-brand-green-500-opacity {
        @apply bg-brand-green-500/[.7] text-brand-green-900;
        h1, h2, h3, h4, h5, h6 { @apply text-brand-green-900; }
        .btn { @apply link-button-brand-green-900; }
    }

    .theme-brand-green-900 {
        @apply bg-brand-green-900 text-white;
        h1, h2, h3, h4, h5, h6 { @apply text-white; }
        .btn { @apply link-button-outline-brand-green-900; }
    }

    .theme-brand-green-900-opacity {
        @apply bg-brand-green-900/[.7] text-white;
        h1, h2, h3, h4, h5, h6 { @apply text-white; }
        .btn { @apply link-button-outline-brand-green-900; }
    }

    .theme-brand-yellow-300 {
        @apply bg-brand-yellow-300 text-[#222222];
        h1, h2, h3, h4, h5, h6 { @apply text-[#222222]; }
        .btn { @apply link-button-brand-green-900; }
    }

    .theme-brand-yellow-300-opacity {
        @apply bg-brand-yellow-300/[.7] text-[#222222];
        h1, h2, h3, h4, h5, h6 { @apply text-[#222222]; }
        .btn { @apply link-button-brand-green-900; }
    }
}
